import { Controller } from '@hotwired/stimulus';
import { enter, leave } from 'el-transition';

// Connects to data-controller="guidebook-reservations"
export default class extends Controller {
  openDialog(e) {
    e.preventDefault();
    enter(document.querySelector('[role="dialog"]'));
    enter(document.querySelector('[data-drawer-target="drawer"]'));
  }

  closeDialog(e) {
    e.preventDefault();
    leave(document.querySelector('[role="dialog"]'));
    leave(document.querySelector('[data-drawer-target="drawer"]'));
  }
}
